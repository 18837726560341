import * as React from 'react';
import { Text as NativeText, TextProps } from 'rebass';
import { usePrimitives } from '../../hooks/useTheme';
import { Flex, Box } from '..';

const Text = React.forwardRef(function TextWithRef(
  props: TextProps & { icon: React.ReactElement },
  ref: React.Ref<any>
): React.ReactElement {
  const { variant, as = 'span', icon, sx, ...rest } = props;

  const { text } = usePrimitives();

  const textComponent = (
    <NativeText
      ref={ref}
      as={as}
      sx={{
        ...text.styles,
        ...text.variants[variant],
        ...sx
      }}
      {...rest}
    />
  );

  if (!icon) return textComponent;

  return (
    <Flex alignItems="center">
      <Box>{icon}</Box>
      <Box ml={'4px'}>{textComponent}</Box>
    </Flex>
  );
});

export { Text };
