import { useThemeUI, useColorMode } from 'theme-ui';

export const useTheme = () => {
  const themeUI = useThemeUI();

  return themeUI.theme;
};

export const useColors = () => {
  const theme = useTheme();
  const [colorMode] = useColorMode();

  return colorMode === 'light' ? theme.colors : theme.colors.modes[colorMode];
};

export const usePrimitives = () => {
  const theme = useTheme();

  return theme.primitives;
};

export const useComponents = () => {
  const theme = useTheme();

  return theme.components;
};
