import * as React from 'react';
import {
  Box as NativeBox,
  BoxProps,
  Flex as NativeFlex,
  FlexProps
} from 'rebass';
import { usePrimitives, useTheme } from '../../hooks/useTheme';
import { useColorMode } from 'theme-ui';

const grid = {
  1: 1 / 12,
  2: 2 / 12,
  3: 3 / 12,
  4: 4 / 12,
  5: 5 / 12,
  6: 6 / 12,
  7: 7 / 12,
  8: 8 / 12,
  9: 9 / 12,
  10: 10 / 12,
  11: 11 / 12,
  12: 12 / 12
};

const computeNewWidth = (width: number | number[]) =>
  Array.isArray(width) ? width.map(w => grid[w]) : grid[width] || width;

export const Box = React.forwardRef(function BoxWithRef(
  props: BoxProps & { elevation?: number },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    children,
    variant,
    width,
    sx,
    elevation: elevationLevel,
    ...rest
  } = props;

  const { box } = usePrimitives();
  const [colorMode] = useColorMode();
  const { elevation } = useTheme();

  const newWidth = computeNewWidth(width);

  return (
    <NativeBox
      ref={ref}
      sx={{
        ...elevation(colorMode)[elevationLevel],
        ...box.styles,
        ...box.variants[variant || box.defaultVariant],
        ...sx
      }}
      {...rest}
      width={newWidth}
    >
      {children}
    </NativeBox>
  );
});

export const Flex = React.forwardRef(function FlexWithRef(
  props: FlexProps & { elevation?: number },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    children,
    variant,
    width,
    sx,
    elevation: elevationLevel,
    ...rest
  } = props;

  const { box } = usePrimitives();
  const newWidth = computeNewWidth(width);
  const [colorMode] = useColorMode();
  const { elevation } = useTheme();

  return (
    <NativeFlex
      ref={ref}
      sx={{
        ...elevation(colorMode)[elevationLevel],
        ...box.variants[variant],
        ...sx
      }}
      {...rest}
      width={newWidth}
    >
      {children}
    </NativeFlex>
  );
});
